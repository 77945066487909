import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
import { format, parseISO } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Background from "../components/Global/Background";
import Carousel from "../components/Global/Carousel";
import Image from "../components/Global/Image";
import Layout from "../components/Templates/Layout";
import Link from "../components/Global/Link";
import Meta from "../utils/Meta";
import Sections from "../components/Sections/Sections";
import SmartText from "../utils/TextHandler";
import Video from "../components/Global/Video";
import SocialLinks from "../components/Global/SocialLinks";

import PurpleCurveImage from "../images/background-curve-purple.svg";
import WhiteCurveImage from "../images/background-curve-white.svg";

// Markup
const IndexPage = (props) => {
  const { data, blok } = props;

  const [isVideoReady, setIsVideoReady] = useState(false);

  let doc;
  // let items;
  if (data) {
    doc = JSON.parse(data.index.content);
    // items = data.news;
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc?.title,
    description: doc?.body,
  };

  const videoPlayHandler = () => {
    setIsVideoReady(true);
  };

  // const newsNodesMap = items?.nodes?.map((newsNode) => {
  //   try {
  //     const parsedNodeContent = JSON.parse(newsNode.content);

  //     return {
  //       full_slug: newsNode.full_slug,
  //       field_date_string: newsNode.field_date_string,
  //       ...parsedNodeContent,
  //     };
  //   } catch (err) {
  //     console.error(err);
  //     return {
  //       full_slug: newsNode.full_slug,
  //       field_date_string: newsNode.field_date_string,
  //     };
  //   }
  // });

  // const processedNewsNodesMap = newsNodesMap?.slice(0, 8).map((news, index) => {
  //   return (
  //     <div
  //       className="px-1 px-md-3 card brand-background-purple border-0"
  //       key={index}
  //     >
  //       <Image
  //         className="card-img-top brand-border-radius-20"
  //         src={news?.image?.filename}
  //         sm="400x300"
  //         md="400x300"
  //         lg="400x300"
  //         alt={news?.image?.alt}
  //       />

  //       <div className="card-body">
  //         {news.field_date_string && (
  //           <p
  //             className="my-3
  //             brand-copy-font brand-text-purple-light
  //             brand-font-size-13 brand-font-size-lg-15
  //             brand-font-weight-400
  //             brand-line-height-17 brand-line-height-lg-19-5"
  //           >
  //             {format(parseISO(news.field_date_string), "dd.MM.yyyy")}
  //           </p>
  //         )}

  //         {news.title && (
  //           <Link
  //             stretched={true}
  //             className="d-block
  //               my-3
  //               brand-copy-font
  //               brand-text-white"
  //             to={news.full_slug}
  //             button="false"
  //           >
  //             <p
  //               className="
  //                 brand-font-size-18 brand-font-size-lg-20
  //                 brand-font-weight-700
  //                 brand-line-height-24 brand-line-height-26"
  //             >
  //               {news.title}
  //             </p>
  //           </Link>
  //         )}
  //       </div>
  //     </div>
  //   );
  // });

  const parsedSiteSettings = data?.settings
    ? JSON.parse(data?.settings?.content)
    : {};

  return (
    <Layout location={props.location} siteSettings={data?.settings}>
      <Meta openGraph={openGraph} meta={doc?.meta} />

      <section
        className={`brand-hero brand-background-black position-relative`}
      >
        <Background
          className={`brand-hero-image`}
          src={doc?.image?.filename}
          sm="768x900"
          md="1920x900"
          lg="1920x900"
          alt={doc?.image?.alt}
        />
        {doc.video && (
          <React.Fragment>
            <Video
              containerClassName={`
              d-none d-lg-block
              ${isVideoReady ? "brand-video-show" : "brand-video-hide"}
            `}
              onVideoPlay={videoPlayHandler}
              autoplay={true}
              background={true}
              light={false}
              loop={true}
              muted={true}
              video={doc.video}
            />

            <Video
              containerClassName={`
              d-block d-lg-none
              ${isVideoReady ? "brand-video-show" : "brand-video-hide"}
            `}
              onVideoPlay={videoPlayHandler}
              autoplay={true}
              background={true}
              light={false}
              loop={true}
              muted={true}
              video={doc.mobile_video}
            />
          </React.Fragment>
        )}

        {/* {doc?.title && (
          <div className="w-100 d-flex justify-content-center brand-text-white brand-hero-title">

            <SmartText containerClassName="text-center brand-index-title px-3">
              {doc.title}
            </SmartText>

            {doc?.body &&
              <div className="text-center">
                <SmartText>{doc.body}</SmartText>
              </div>
            }
          </div>
        )} */}

        {doc?.has_curved_header && (
          <img
            src={WhiteCurveImage}
            className="brand-white-curve px-0"
            alt="White curve"
          />
        )}
      </section>

      {doc?.sections && (
        <Sections items={doc.sections} settings={parsedSiteSettings} />
      )}

      {/* <div className="container-fluid mt-5 px-0">
        <div className="row px-0">
          <img src={PurpleCurveImage} className="brand-purple-curve px-0" />
        </div>
      </div>

      <div
        className="
          pb-3
          brand-background-purple
          brand-text-white
        "
      >
        <h2
          className="text-center p-0 py-3 pt-4
            brand-font-size-40 brand-font-size-lg-100
            brand-line-height-40 brand-line-height-lg-100"
        >
          Keep up to date with our news
        </h2>

        <SocialLinks
          className="brand-background-purple m-0 text-center brand-social-links-white"
          itemClassName="mx-3"
          links={[
            ["facebook", parsedSiteSettings?.facebook],
            ["twitter", parsedSiteSettings?.twitter],
            ["instagram", parsedSiteSettings?.instagram],
            ["linkedin", parsedSiteSettings?.linkedin],
          ]}
        />
      </div> */}

      {/*
      <Carousel
        itemsToShow={1}
        hasPurpleBackground={true}
        className="brand-background-purple brand-text-white d-block d-lg-none"
      >
        {processedNewsNodesMap}
      </Carousel>

      <Carousel
        itemsToShow={4}
        hasPurpleBackground={true}
        className="brand-background-purple brand-text-white d-none d-lg-block"
      >
        {processedNewsNodesMap}
      </Carousel>

      <section className="container-fluid p-0 brand-background-purple">
        <div className="d-flex px-5 py-3 justify-content-center">
          <Link
            to="news"
            button="true"
            className="
              my-3
              px-4 py-3
              text-center
              brand-btn-rounded-yellow-fill
              brand-text-purple"
          >
            <span
              className="brand-font-size-15 brand-font-size-lg-18
              brand-font-weight-700 brand-font-weight-lg-600
              brand-line-height-15 brand-line-height-lg-24 brand-text-purple"
            >
              ALL NEWS
            </span>
          </Link>
        </div>
      </section> */}
    </Layout>
  );
};

export default IndexPage;

export const QUERY = graphql`
  query IndexPageQuery {
    index: storyblokEntry(slug: { eq: "home" }) {
      content
    }

    settings: storyblokEntry(
      slug: { eq: "settings" }
      lang: { eq: "default" }
    ) {
      content
    }
  }
`;
// export const QUERY = graphql`
//   query IndexPageQuery {
//     index: storyblokEntry(slug: { eq: "home" }) {
//       content
//     }

//     # Grab news articles
//     news: allStoryblokEntry(
//       filter: { field_component: { eq: "news_post" } }
//       sort: {
//         fields: [field_featured_boolean, field_date_string]
//         order: [DESC, DESC]
//       }
//     ) {
//       nodes {
//         full_slug
//         field_date_string
//         content
//       }
//     }

//     settings: storyblokEntry(
//       slug: { eq: "settings" }
//       lang: { eq: "default" }
//     ) {
//       content
//     }
//   }
// `;
